<template>
  <b-modal id="bv-modal-example" v-model="modal" size="lg" hide-footer>
    <template #modal-title> Mesa: {{ game.name || 'NO GAME NAME' }} </template>
    <div v-if="Object.keys(round).length">
      <div>
        <h5>CARTAS DEL CRUPIER:</h5>
        <div class="card-accepted-container">
          <div
            class="card-accepted"
            v-for="(card, i) in round.result.croupierHands"
            :key="i"
            :style="`color: ${mapColor(card.suit)}`"
          >
            <span class="card-left-number">{{ card.name }}</span>
            <span class="card-type">{{ cardTypes[card.suit] }}</span>
            <span class="card-right-number">{{ card.name }}</span>
          </div>
        </div>
      </div>
      <div>
        <h5>CARTAS DEL JUGADOR:</h5>
        <div class="card-accepted-container">
          <div
            class="card-accepted"
            v-for="(card, i) in round.result.playerHands"
            :key="i"
            :style="`color: ${mapColor(card.suit)}`"
          >
            <span class="card-left-number">{{ card.name }}</span>
            <span class="card-type">{{ cardTypes[card.suit] }}</span>
            <span class="card-right-number">{{ card.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    game: {
      type: Object,
      default: () => ({ name: 'NO GAME' }),
    },
    round: {
      type: Object,
      default: () => ({
        result: {
          croupierHands: [
            {
              uuid: 'spades_10',
              name: '10',
              suit: 'spades',
              isHidden: false,
            },
            {
              uuid: 'spades_A',
              name: 'A',
              suit: 'spades',
              isHidden: false,
            },
          ],
          playerHands: [
            {
              uuid: 'spades_10',
              name: '10',
              suit: 'spades',
              isHidden: false,
            },
            {
              uuid: 'spades_A',
              name: 'A',
              suit: 'spades',
              isHidden: false,
            },
          ],
        },
      }),
    },
  },
  computed: {
    mapColor: () => (suit) => {
      return ['clubs', 'spades'].includes(suit) ? 'black' : 'red';
    },
  },
  data() {
    return {
      modal: false,
      cardTypes: {
        clubs: '♣',
        spades: '♠',
        hearts: '♥',
        diamonds: '♦',
      },
    };
  },
};
</script>

<style scoped>
.card-accepted-container {
  display: grid;
  justify-content: start;
  grid-auto-flow: column;
  overflow-x: scroll;
}

.card-accepted {
  background-color: rgb(231, 231, 231);
  box-shadow: 0 0 20px rgb(100, 100, 100);
  display: inline-grid;
  border-radius: 5px;
  height: 250px;
  width: 150px;
  /* padding: 10px; */
  grid-template-columns: 30% 40% 30%;
  font-weight: bolder;
  font-size: 2rem;
  grid-template-rows: repeat(3, 33.3%);
  margin: 1rem;
}

.card-type {
  grid-row: 2/3;
  grid-column: 2/3;
  justify-self: center;
  font-size: 4rem;
  align-self: center;
}

.card-right-number {
  grid-row: 3/4;
  grid-column: 3/4;
  justify-self: end;
  align-self: end;
}
</style>
