<template>
  <b-modal id="bv-modal-example" v-model="modal" size="xl" hide-footer>
    <template #modal-title>{{ $t('tableReports.roundSister') }}</template>
    <div class="card" v-if="Object.entries(round).length">
      <p>
        {{ $t('transactions.roundID') }}: <strong>{{ round.code }}</strong>
      </p>
      <p>
        {{ $t('tableReports.roundIdentifier') }}:
        <strong>{{ roundIdentifierNumber }}</strong>
      </p>
      <div v-if="rounds.length">
        <b-table :fields="fields" responsive="md" :items="rounds">
          <template #cell(start_date)="data">
            <div
              class="createdAt"
              @mouseover="handleOver"
              @mouseleave="handleLeave"
            >
              {{ moment(data.item.start_date).format('DD/MM/YYYY | HH:mm:ss') }}
              <span class="badge badge-time bg-primary rounded-pill"
                >Server time:
                {{
                  moment
                    .utc(data.item.start_date)
                    .format('DD/MM/YYYY | HH:mm:ss')
                }}</span
              >
            </div>
          </template>
          <template #cell(end_date)="data">
            <div
              class="createdAt"
              @mouseover="handleOver"
              @mouseleave="handleLeave"
            >
              {{ moment(data.item.end_date).format('DD/MM/YYYY | HH:mm:ss') }}
              <span class="badge badge-time bg-primary rounded-pill"
                >Server time:
                {{
                  moment.utc(data.item.end_date).format('DD/MM/YYYY | HH:mm:ss')
                }}</span
              >
            </div>
          </template>
          <template #cell(result)="data">
            <span :class="`text-light p-1  ${colorResult(data.item.result)}`">
              {{ data.item.result }}
            </span>
          </template>
        </b-table>
      </div>
      <div>
        <vs-button
          @click="handleManualPayment"
          :disabled="!rounds.filter((round) => round.bets > 0).length"
        >
          {{ $t('betDetail.pay') }}
        </vs-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex';
export default {
  props: ['round', 'rounds', 'roundIdentifierNumber'],
  computed: {
    ...mapGetters({
      colorResult: 'reports/colorResult',
    }),
  },
  data() {
    return {
      modal: false,
      fields: [
        {
          key: 'code',
          label: this.$t('transactions.roundCode'),
          class: 'text-center space-nowrap',
        },
        {
          key: 'result',
          label: this.$t('tableReports.result'),
        },
        {
          key: 'bets',
          label: this.$t('tableReports.bets'),
        },
        {
          key: 'start_date',
          label: this.$t('tableReports.initDate'),
          class: 'text-center space-nowrap',
        },
        {
          key: 'end_date',
          label: this.$t('tableReports.endDate'),
          class: 'text-center space-nowrap',
        },
      ],

      reds: [
        1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 25, 27, 30, 32, 34, 36,
      ],
    };
  },
  methods: {
    closeModa() {
      this.modal = false;
      this.items = [];
    },
    handleOver(e) {
      const element = e.target;
      const badge = element.querySelector('.badge-time');
      if (badge) {
        badge.style.visibility = 'visible';
      }
    },
    handleLeave(e) {
      const element = e.target;
      const badge = element.querySelector('.badge-time');
      if (badge) {
        badge.style.visibility = 'hidden';
      }
    },
    async handleManualPayment() {
      const { value } = await Swal.fire({
        title: '¿Estás seguro de realizar el pago?',
        icon: 'info',
        confirmButtonText: 'Sí',
        denyButtonText: 'No',
        showDenyButton: true,
      });

      if (value) {
        try {
          const { data } = await this.$http.post('bets/manual-pay', {
            identifierNumber: this.roundIdentifierNumber,
            result: this.round.result,
          });

          return Swal.fire({
            title: 'Operacion realizada',
            text: `Pagos exitosos: ${data.successBets.length}. Pagos fallidos: ${data.failBets.length}`,
            icon: 'success',
          });
        } catch (error) {
          console.log('Error al realizar el pago manual', error);
        }
      }

      return Swal.fire({
        title: 'Error realizando la transaccion',
        icon: 'info',
      });
    },
  },
};
</script>

<style scoped>
.space-nowrap {
  white-space: nowrap !important;
}

.black {
  background: #000;
  color: #fff;
  padding: 4px;
  text-align: center;
}

.red {
  background: #f10;
  color: #fff;
  padding: 4px;
  text-align: center;
}

.green {
  background: #00a429;
  color: #fff;
  padding: 4px;
  text-align: center;
}
</style>
